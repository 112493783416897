import React, { useState, useEffect } from 'react';
import { useAuth } from '../../authContext';
import { useNavigate } from "react-router-dom";
import Dashnav from '../../components/dashnav';
import "./style.css";
import ProfileDef from "../dashboard/profileIcon.svg";
import BenchIcon from "./DashIcon.svg";
import newCandidateIcon from "../dashboard/RegisterIcon.svg"
import ViewIcon from "../benchcandidate/view-icon.svg";
import EditIcon from "../benchcandidate/edit-icon.svg";
import CandidateDetailsModal from '../benchcandidate/CandidateDetailsModal'; // Your new modal component
import CandidateModal from "../benchcandidate/candidateModal";

const MyAssignedCandidates = () => {

    const [candidates, setCandidates] = useState([]);
    const [filteredCandidates, setFilteredCandidates] = useState([]);
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [visaStatusFilter, setVisaStatusFilter] = useState('');
    const [locationFilter, setLocationFilter] = useState('');
    const [candidateCount, setCandidateCount] = useState('');
    const [recruitersList, setRecruitersList] = useState([]); // State for recruiters list
    const [teamsFilter, setTeamsFilter] = useState('');
    const [currentStatus, setCurrentStatus] = useState("");
    const { isLoggedIn, login, user} = useAuth();
    const [dataDelete, setDataDelete] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Ensure user is available before making the API call
        if (user?.email) {
            // Fetch candidates data on component mount
            fetch('https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: `SELECT id, first_name, last_name, primary_email, secondary_email, 
                            primary_contact, secondary_contact, location_preference , visa_status, 
                            current_status, currently_in_project, availability, candidate_onedrive_link, 
                            comments, assigned_recruiter, cvisa_status, opt_letter_status, primary_technology, secondary_technology, assigned_team 
                            FROM foursphere_recruiters.bench_candidates 
                            WHERE assigned_recruiter = '${user.email}'`
                })
            })
            .then(response => response.json())
            .then(data => setCandidates(data))
            .catch(error => console.error('Error fetching candidates:', error));
        }
    }, [user]);
    // Fetch recruiters list from the API
    useEffect(() => {
        const fetchRecruiters = async () => {
            try {
                const query = `SELECT id, assigned_recruiter FROM foursphere_recruiters.bench_candidates`;
                const response = await fetch("https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ query })
                });

                const data = await response.json();
                if (response.ok) {
                    setRecruitersList(data);
                    console.log(data);
                } else {
                    console.error('Failed to fetch recruiters:', data.message);
                }
            } catch (error) {
                console.error('Error fetching recruiters:', error);
            }
        };

        fetchRecruiters();
    }, []);

    useEffect(() => {
        let filtered = candidates;

        // Search by name
        if (searchTerm) {
            filtered = filtered.filter(candidate =>
                candidate.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                candidate.last_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        // Filter by visa status
        if (visaStatusFilter) {
            filtered = filtered.filter(candidate => {
                // If the visa status is "OPT", also check the "OPT Letter Provided" status
                if (visaStatusFilter === 'OPT') {
                    return candidate.visa_status === 'OPT' ;
                }
                // Otherwise, just filter by the visa status
                return candidate.visa_status === visaStatusFilter;
            });
        }
        

        

        // Filter by Current status
            if (currentStatus) {
                filtered = filtered.filter(candidate =>
                    candidate.current_status === currentStatus
                );
            }
         // Filter by assigned teams
if (teamsFilter) {
    filtered = filtered.filter(candidate => {
        // Ensure to handle both string and array formats for assigned_team
        if (Array.isArray(candidate.assigned_team)) {
            return candidate.assigned_team.includes(teamsFilter);
            // console.log(`filter1 ${candidate.assigned_team}`);
            
        } else {
            return candidate.assigned_team === teamsFilter;
            // console.log(`filter1 ${candidate.assigned_team}`);
        }
    });
}

        // Filter by location preference
        if (locationFilter) {
            filtered = filtered.filter(candidate =>
                candidate.location_preference === locationFilter
            );
        }

        setFilteredCandidates(filtered);
    }, [searchTerm, visaStatusFilter, locationFilter, currentStatus, teamsFilter, candidates]);

    const openModal = (candidate) => {
        setSelectedCandidate(candidate);
    };

    const closeModal = () => {
        setSelectedCandidate(null);
    };

    const saveCandidate = (updatedCandidate) => {
        // Update candidate in the state
        const updatedCandidates = candidates.map(candidate =>
            candidate.id === updatedCandidate.id ? updatedCandidate : candidate
        );
        setCandidates(updatedCandidates);

        // Close the modal
        closeModal();

        // POST request to update candidate in the database
        fetch('https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: `UPDATE foursphere_recruiters.bench_candidates 
                        SET first_name = '${updatedCandidate.first_name}', 
                            last_name = '${updatedCandidate.last_name}', 
                            primary_email = '${updatedCandidate.primary_email}', 
                            secondary_email = '${updatedCandidate.secondary_email}', 
                            primary_contact = '${updatedCandidate.primary_contact}', 
                            secondary_contact = '${updatedCandidate.secondary_contact}', 
                            location_preference = '${updatedCandidate.location_preference}', 
                            visa_status = '${updatedCandidate.visa_status}', 
                            current_status = '${updatedCandidate.current_status}', 
                            assigned_recruiter = '${updatedCandidate.assigned_recruiter}', 
                            currently_in_project = '${updatedCandidate.currently_in_project}', 
                            availability = '${updatedCandidate.availability}', 
                            candidate_onedrive_link = '${updatedCandidate.candidate_onedrive_link}', 
                            comments = '${updatedCandidate.comments}', 
                            cvisa_status = '${updatedCandidate.cvisa_status}', 
                            opt_letter_status = '${updatedCandidate.opt_letter_status}', 
                            primary_technology = '${updatedCandidate.primary_technology}', 
                            secondary_technology = '${updatedCandidate.secondary_technology}',
                            assigned_team = '${updatedCandidate.assigned_team}' 
                        WHERE id = ${updatedCandidate.id};`
            })
        })
        .then(response => {
            if (response.ok) {
                console.log('Candidate updated successfully');
            } else {
                console.error('Error updating candidate:', response.statusText);
            }
        })
        .catch(error => console.error('Error updating candidate:', error));
    };
    
    function goToRegister() {
        navigate("/registerNewCandidates");
    }

    useEffect(() => {
        // Fetch the number of active bench candidates from the API
        fetch('https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: `SELECT COUNT(*) AS count FROM foursphere_recruiters.bench_candidates WHERE current_status = 'Active';`
            })
        })
        .then(response => response.json())
        .then(data => setCandidateCount(data[0].count))  // Assuming the API returns an array with count as the first element
        .catch(error => console.error('Error fetching candidate count:', error));
    }, []);

    const sortCandidatesByFirstName = (candidates) => {
        return candidates.sort((a, b) => {
          // Safely check if first_name exists, sort alphabetically by first_name
          const nameA = a.first_name ? a.first_name.toLowerCase() : '';
          const nameB = b.first_name ? b.first_name.toLowerCase() : '';
      
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      };
      
      // Example usage in your component
      const sortedCandidates = sortCandidatesByFirstName(filteredCandidates);

      const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false); // Modal state
const [currentCandidate, setCurrentCandidate] = useState(null); // Candidate data
const [isAdditionalModalOpen, setIsAdditionalModalOpen] = useState(false); // Subview modal state

// Function to open the details modal and fetch data
const handleOpenDetailsModal = (candidateEmail) => {
    {
        // Fetch candidates data on component mount
        fetch('https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: `SELECT cr.email, cr.from_email, cr.to_email, cr.candidate_full_name, cr.client_name, cr.rate, cr.submission_status, MAX(cr.submission_date) as latest_submission_date    FROM foursphere_recruiters.candidate_rateconfirmations cr   JOIN foursphere_recruiters.bench_candidates bc  ON cr.email = bc.primary_email OR cr.email = bc.secondary_email    WHERE cr.email = '${candidateEmail}'     GROUP BY cr.email, cr.from_email, cr.to_email, cr.candidate_full_name, cr.client_name, cr.rate, cr.submission_status   ORDER BY cr.email ASC, latest_submission_date DESC`
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data && data.length > 0) {
                setCurrentCandidate(data); // Set candidate data
                setIsDetailsModalOpen(true); // Open the details modal
            }
        })
        .catch(error => console.error('Error fetching candidate details:', error));
};}

// Function to open the additional modal/subview
const handleOpenAdditionalModal = () => {
    setIsAdditionalModalOpen(true); // Open additional modal/subview
};

// Close functions for both modals
const handleCloseDetailsModal = () => setIsDetailsModalOpen(false);
const handleCloseAdditionalModal = () => setIsAdditionalModalOpen(false);

//delete the hovered bench Candidate
const deleteCandidate = async(candidate) => {
    if (window.confirm('Are you sure you want to delete')) {
        try {
            const response = await fetch(`https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: `DELETE FROM foursphere_recruiters.bench_candidates WHERE id = ${candidate.id};`
                })
            })
            if (response.ok) {
                // alert candidate is deleted and list is updated
                alert ('clicked candidate is Deleted successfully and list is updated');
                setDataDelete(true);
            }
            else {
                throw new Error('failed to delete candidates');
            }
        }
        catch (error) {
            console.error('Error deleting candidate:' + error.message);
        }
    }
}


const ProfileOpen = () => {
    // Open the profile page
    navigate("/userProfile");
    // or navigate("/register", { replace: true }); // replace current URL with new one, without adding it to the history stack
}
    return (
        <div>
            <div className="main-dash row-flex">
                <Dashnav />
                <div className="content col-flex">
                    <div className="top-bar row-flex">
                        <div className="col-flex">
                            <div className="page-intro row-flex">
                                <img src={BenchIcon} alt="page-icon" />
                                <h3 className="page-head blue">My Assigned Candidates</h3>
                            </div>
                        </div>
                        <div className="col-flex">
                            <div className="profile-button row-flex" onClick={ProfileOpen}>
                                <img src={ProfileDef} alt="" className="thumb" />
                                <div className="user-col col-flex">
                                    <h3 className="position">Your Profile</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row-flex benchhead">
                        <div className="new-candidates row-flex green" onClick={goToRegister}>
                            <img src={newCandidateIcon} alt="newCandidateIcon" />
                            Register new candidate
                        </div>
                        <div className="new-candidates row-flex">
                            No. Of Candidates :
                            <span>{filteredCandidates.length}</span>
                        </div>
                    </div>
                    <div className="filterBg col-flex">
                        <h4 className="head">
                            <i className="fas fa-filter" style={{ marginRight: '8px' }}></i>
                            Filters
                        </h4>
                        <div className="filters row-flex">
                            <input 
                                type="text" 
                                placeholder="Search by name" 
                                value={searchTerm} 
                                onChange={(e) => setSearchTerm(e.target.value)} 
                            />
                            <select 
                                value={visaStatusFilter} 
                                onChange={(e) => setVisaStatusFilter(e.target.value)}
                            >
                                <option value="">Filter by Visa Status</option>
                                <option value="OPT">OPT</option>
                                <option value="CPT">CPT</option>
                                <option value="H1B">H1B</option>
                                <option value="H4 EAD">H4 EAD</option>
                                <option value="GC">GC</option>
                                <option value="GC EAD">GC EAD</option>
                                <option value="USC">USC</option>
                                <option value="STEM OPT">STEM OPT</option>
                            </select>
                            <select
                                type="text"
                                placeholder="current Status"
                                value={currentStatus}
                                onChange={(e) => setCurrentStatus(e.target.value)}
                            >
                                <option value="">current status</option>
                                {[...new Set(candidates.map(item => item.current_status))]
                                    .map((statusActive, index) => (
                                        <option key={index} value={statusActive}>{statusActive}</option>
                                ))}
                            </select>
                            <select 
                                type="text"
                                placeholder="Filter by Teams"
                                value={teamsFilter} 
                                onChange={(e) => setTeamsFilter(e.target.value)} 
                            >
                                <option value="">Select Teams</option>
                                
                                {[...new Set(candidates.map(item => item.assigned_team))]
                                    .map((team, index) => (
                                        <option key={index} value={team}>{team}</option>
                                ))}
                            </select>
                            <select 
                                value={locationFilter} 
                                onChange={(e) => setLocationFilter(e.target.value)}
                            >
                                <option value="">Filter by Location Preference</option>
                                {[...new Set(candidates.map(item => item.location_preference))]
                                    .map((location, index) => (
                                        <option key={index} value={location}>{location}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="innerContent col-flex">
                        <div className="bench-row row-flex">
                            {sortedCandidates.map(candidate => (
                                <div 
                                    key={candidate.id} 
                                    className="bench-cards col-flex" 
                                >
                                    <div className="details row-flex">
                                        <span className="object">Name :</span>
                                        <span className="value">{`${candidate.first_name} ${candidate.last_name}`}</span>
                                    </div>
                                    <div className="details row-flex">
                                        <span className="object">Email :</span>
                                        <span className="value">{candidate.primary_email}</span>
                                    </div>
                                    <div className="details row-flex">
                                        <span className="object">Contact :</span>
                                        <span className="value">{candidate.primary_contact}</span>
                                    </div>
                                    <div className="details row-flex">
                                        <span className="object">Visa Status :</span>
                                        <span className="value">{candidate.visa_status}</span>
                                    </div>
                                    <div className="details row-flex">
                                        <span className="object">Current Status :</span>
                                        <span className="value">{candidate.current_status}</span>
                                    </div>
                                    <div className="details row-flex clamp">
                                        <span className="object">Assigned Recruiter :</span>
                                        <span className="value">{candidate.assigned_recruiter}</span>
                                    </div>
                                    <div className="Overlay">
                                    <button className="view-btn" 
                                        onClick={() => {
                                            handleOpenDetailsModal(candidate.primary_email); // Open details modal
                                            handleOpenAdditionalModal(); // Open subview/modal
                                        }}
                                    >
                                        <img src={ViewIcon} alt="" className="icon" />
                                    </button>

                                        <button className="view-btn"
                                        onClick={() => openModal(candidate)}
                                        >
                                            <img src={EditIcon} alt="" className="icon" />
                                        </button>
                                        <button className="view-btn delete"
                                        onClick={() => deleteCandidate(candidate)}
                                        >
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {isDetailsModalOpen && currentCandidate && (
            <CandidateDetailsModal
                candidate={currentCandidate}
                onClose={handleCloseDetailsModal}
            />
        )}

        {selectedCandidate && (
            <CandidateModal
                candidate={selectedCandidate}
                onClose={closeModal}
                onSave={saveCandidate}
            />
        )}

            
        </div>
    )
}
export default MyAssignedCandidates