import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from '../../authContext';
import Dashnav from '../../components/dashnav';
import "./style.css";
import ProfileDef from "./profileIcon.svg";
import UserDash from "./user-dash.png";
import BenchIcon from "./benchdashicon.svg";
import PageIcon from "./DashIcon.svg";
import RegisterIcon from "./RegisterIcon.svg";
import CandidatesIcon from "../myAssignedCandidates/DashIcon.svg"
import MySubmission from '../mysubmission/mySubmission';

const Dashboard = () => {
    const { isLoggedIn, login, user } = useAuth();
    const [candidateCount, setCandidateCount] = useState(0);
    const [totalCandidates, setTotalCandidates] = useState(0);
    const [assignedCandidates, setAssignedCandidates] = useState(0);
    const [mySubmissions, setMySubmissions] = useState([]);
    const [emailAttempted, setEmailAttempted] = useState(false); // Track if email has been attempted
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            console.log(user);
        }
    }, [login, user]);

    const ProfileOpen = () => {
        navigate("/userProfile");
    };

    useEffect(() => {
        if(user && user.user_role === "admin"){
            // Fetch the number of active bench candidates from the API
            fetch('https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: `SELECT COUNT(*) AS count FROM foursphere_recruiters.bench_candidates WHERE current_status = 'Active';`
                })
            })
            .then(response => response.json())
            .then(data => setCandidateCount(data[0].count))
            .catch(error => console.error('Error fetching candidate count:', error));
        }
        else if(user?.user_role === "recruiter"){
            console.log("You are a Recruiter");
        }
    }, [user]);

    useEffect(() => {
        // Fetch the number of assigned candidates for the current user
        if (user && user.user_role) {
            fetch('https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: `SELECT COUNT(*) AS count FROM foursphere_recruiters.bench_candidates WHERE assigned_recruiter = '${user.email}';`
                })
            })
            .then(response => response.json())
            .then(data => setAssignedCandidates(data[0].count))
            .catch(error => console.error('Error fetching assigned candidate count:', error));
        }
    }, [user, user?.email]);

    const goToCanditates = () => {
        navigate("/benchcandidates");
    };

    const goToRegisterCanditates = () => {
        navigate("/registerNewCandidates");
    };

    const goToRateCanditates = () => {
        navigate("/rateCandidates");
    };

    const goToMyAssignedCandidates = () => {
        navigate("/myAssignedCandidates");
    };

    const username = user ? user.email.split('@')[0] : "Your Name";

    useEffect(() => {
        if(user && user.user_role === "admin"){
            fetch('https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: `SELECT COUNT(*) AS count FROM foursphere_recruiters.candidate_rateconfirmations`
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data && data.length > 0 && data[0].count !== undefined) {
                    setTotalCandidates(data[0].count);
                } else {
                    console.error('Unexpected data format:', data);
                    setTotalCandidates(0);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setTotalCandidates(0);
            });
        }
        else if(user?.user_role === "recruiter") {
            console.log("Recruiter Logged In");
        }
    }, [user]);

    useEffect(() => {
        if(user && user.user_role === "recruiter"){
        // Check if user and user.email are available
            
            const fetchCandidates = (email) => {
                fetch('https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        query: `SELECT from_email, to_email, candidate_full_name, client_name, rate, email, contact, signature, submission_status, submission_date, thread_id, id 
                                FROM foursphere_recruiters.candidate_rateconfirmations
                                WHERE from_email = '${email}'`
                    })
                })
                .then(response => response.json())
                .then(data => {
                    if (data && data.length > 0) {
                        setMySubmissions(data); // Set candidate data
                        setEmailAttempted(true); // Mark email as attempted
                    } else {
                        if (!emailAttempted) {
                            // Capitalize the first letter of email and resubmit
                            const modifiedEmail = user.email.charAt(0).toUpperCase() + user.email.slice(1);
                            fetchCandidates(modifiedEmail);
                            setEmailAttempted(true); // Mark email as attempted
                        }
                    }
                })
                .catch(error => console.error('Error fetching candidate details:', error));
            };

            fetchCandidates(user.email); // Fetch candidates initially
        }
        else if (user?.user_role === 'admin') {
            console.log("Admin user logged in");
        }
    }, [user, user?.email, emailAttempted]);

    return (
        <div>
            <div className="main-dash row-flex">
                <Dashnav />
                <div className="content col-flex">
                    <div className="top-bar row-flex">
                        <div className="col-flex">
                            <div className="page-intro row-flex">
                                <img src={PageIcon} alt="page-icon" />
                                <h3 className="page-head blue">Dashboard</h3>
                            </div>
                        </div>
                        <div className="col-flex">
                            <div className="profile-button row-flex" onClick={ProfileOpen}>
                                <img src={ProfileDef} alt="" className="thumb" />
                                <div className="user-col col-flex">
                                    <h3 className="position">Your Profile</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="innerContent col-flex">
                        <div className="user-dash row-flex">
                            <div className="user-col col-flex">
                                <h2>Stay Ahead in Recruitment</h2>
                                <h3>Welcome {username}</h3>
                            </div>
                            <div className="user-col col-flex">
                                <img src={UserDash} alt="" className="userdash-thumb" />
                            </div>
                        </div>
                        <div className="bench-row row-flex">
                            {user?.user_role === 'admin' &&
                                <div className="dash-cards col-flex" onClick={goToCanditates}>
                                    <div className="info-row row-flex">
                                        <div className="info-col col-flex">
                                            <div className="icon"><img src={BenchIcon} alt="BenchIcon" /></div>
                                            <h5>No. Of Active Candidates</h5>
                                        </div>
                                        <div className="info-col">
                                            <span>{candidateCount}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            {user?.user_role === 'recruiter' &&
                                <div className="dash-cards col-flex" onClick={goToMyAssignedCandidates}>
                                    <div className="info-row row-flex">
                                        <div className="info-col col-flex">
                                            <div className="icon"><img src={BenchIcon} alt="BenchIcon" /></div>
                                            <h5>No. Of Rate Submissions</h5>
                                        </div>
                                        <div className="info-col">
                                            <span>{mySubmissions.length}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            {user?.user_role === 'recruiter' &&
                                <div className="dash-cards col-flex" onClick={goToMyAssignedCandidates}>
                                    <div className="info-row row-flex">
                                        <div className="info-col col-flex">
                                            <div className="icon"><img src={CandidatesIcon} alt="BenchIcon" /></div>
                                            <h5>No. Of Assigned Candidates</h5>
                                        </div>
                                        <div className="info-col">
                                            <span>{assignedCandidates}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            {user?.user_role === 'admin' &&
                                <div className="dash-cards col-flex" onClick={goToRateCanditates}>
                                    <div className="info-row row-flex">
                                        <div className="info-col col-flex">
                                            <div className="icon"><img src={BenchIcon} alt="BenchIcon" /></div>
                                            <h5>Total Candidates</h5>
                                        </div>
                                        <div className="info-col">
                                            <span>{totalCandidates}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="dash-cards col-flex" onClick={goToRegisterCanditates}>
                                <div className="info-row row-flex">
                                    <div className="info-col col-flex">
                                        <div className="icon"><img src={RegisterIcon} alt="BenchIcon" /></div>
                                        <h5>Register New Candidates</h5>
                                    </div>
                                    <div className="info-col">
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
